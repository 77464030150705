import { FC, useMemo, memo, useCallback } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useTranslation } from 'react-i18next';
import Hidden from 'components/ui/Hidden';

import { LINKS } from 'constants/constants';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import { ContentContainer } from 'containers/layouts/LandingLayout/styled';
import {
  StepsBarWrapper,
  StepsWrapper,
  StepWrapper,
  ImageWrapper,
  StepTitle,
  StepDescription,
  StepsButton,
  StepsBlockTitle,
} from './styled';

const stepOne = '/images/landing/step1.svg';
const stepTwo = '/images/landing/step2.svg';
const stepThree = '/images/landing/step3.svg';

const Steps: FC = () => {
  const { t } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();
  const router = useRouter();

  const keys = useMemo(
    () => ({
      title: 'qr.page.landing.general.steps.title',
      mainCTA: 'qr.page.landing.general.main.cta',

      stepOneTitle: 'qr.page.landing.general.steps.stepOneTitle',
      stepOneDescription: 'qr.page.landing.general.steps.stepOneDescription',

      stepTwoTitle: 'qr.page.landing.general.steps.stepTwoTitle',
      stepTwoDescription: 'qr.page.landing.general.steps.stepTwoDescription',

      stepThreeTitle: 'qr.page.landing.general.steps.stepThreeTitle',
      stepThreeDescription: 'qr.page.landing.general.steps.stepThreeDescription',
    }),
    []
  );

  const text = useMemo(
    () => ({
      title: t(keys.title, '3 easy steps to create your own QR Code'),
      mainCTA: t(keys.mainCTA, 'Create QR Code'),

      stepOneTitle: t(keys.stepOneTitle, '1. Choose the content of your QR'),
      stepOneDescription: t(
        keys.stepOneDescription,
        'Select from a wide variety of options: PDF, menu, video, business cards, web, apps, etc.'
      ),

      stepTwoTitle: t(keys.stepTwoTitle, '2. Customise and design it as you like'),
      stepTwoDescription: t(
        keys.stepTwoDescription,
        'Fill in all the information and use our QR generator to get a unique design.'
      ),

      stepThreeTitle: t(keys.stepThreeTitle, '3. Download your QR Code'),
      stepThreeDescription: t(
        keys.stepThreeDescription,
        'Get your QR in different formats (pdf, png, svg), print it or show it in a digital format and voila!'
      ),
    }),
    [keys, t]
  );

  const steps = [
    {
      imageSrc: stepOne,
      title: text.stepOneTitle,
      description: text.stepOneDescription,
      titleKey: keys.stepOneTitle,
      descriptionKey: keys.stepOneDescription,
      width: 93,
      height: 80,
    },
    {
      imageSrc: stepTwo,
      title: text.stepTwoTitle,
      description: text.stepTwoDescription,
      titleKey: keys.stepTwoTitle,
      descriptionKey: keys.stepTwoDescription,
      width: 80,
      height: 80,
    },
    {
      imageSrc: stepThree,
      title: text.stepThreeTitle,
      description: text.stepThreeDescription,
      titleKey: keys.stepThreeTitle,
      descriptionKey: keys.stepThreeDescription,
      width: 93,
      height: 83,
    },
  ];

  const onCTAClick = useCallback(() => {
    router.push(LINKS.BUILDER_BASE);
  }, [router]);

  return (
    <ContentContainer>
      <StepsBarWrapper>
        <Hidden smUp implementation="css">
          <StepsBlockTitle {...getDataAttributes(keys.title)}>{text.title}</StepsBlockTitle>
        </Hidden>
        <StepsWrapper>
          {steps.map((step) => (
            <Step {...step} key={step.title} />
          ))}
        </StepsWrapper>
        <Hidden smUp implementation="css">
          <StepsButton style={{ zIndex: 2 }} onClick={onCTAClick} {...getDataAttributes(keys.mainCTA)}>
            {text.mainCTA}
          </StepsButton>
        </Hidden>
      </StepsBarWrapper>
    </ContentContainer>
  );
};

const Step: FC<{
  imageSrc: string;
  title: string;
  description: string;
  titleKey: string;
  descriptionKey: string;
  width: number;
  height: number;
}> = memo(({ imageSrc, title, description, titleKey, descriptionKey, width, height }) => {
  const { getDataAttributes } = useTranslationEditorContext();

  return (
    <StepWrapper>
      <ImageWrapper className="stepIcon">
        <Image src={imageSrc} alt={title} width={width ?? 80} height={height ?? 80} priority />
      </ImageWrapper>
      <StepTitle {...getDataAttributes(titleKey)}>{title}</StepTitle>
      <StepDescription {...getDataAttributes(descriptionKey)}>{description}</StepDescription>
    </StepWrapper>
  );
});

export default memo(Steps);
