import styled from '@emotion/styled';
import { mediaMinMax, mediaMax, mediaMin, breakpoints } from 'styles';
import { BlockTitle } from '../commonStyled';

export const MainContainer = styled.div`
  margin-top: 72px;

  ${mediaMinMax(breakpoints.tabletL, breakpoints.laptopS)} {
    margin-top: 42px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    margin-top: 42px;
  }
`;

export const MainCTAWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  ${mediaMin(breakpoints.tabletM)} {
    flex-flow: row;
  }
`;

export const CTABLockWrapper = styled.div`
  width: 100%;
  z-index: 1;

  ${mediaMin(breakpoints.tabletM)} {
    position: relative;
    width: 45%;
  }

  ${mediaMin(breakpoints.tabletL)} {
    width: 501px;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  max-width: 330px;
  color: var(--qr-global-color-text-primary);

  ${mediaMin(breakpoints.tabletL)} {
    font-size: 38px;
    line-height: 43px;
    margin-top: 40px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    margin-top: 120px;
  }
`;

export const PointsWrapper = styled.ul`
  margin-top: 9px;
  margin-bottom: 14px;

  ${mediaMin(breakpoints.tabletL)} {
    margin: 20px 0 18px;
  }
`;

export const Point = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  color: var(--qr-global-color-text-secondary);

  ${mediaMin(breakpoints.tabletL)} {
    margin-bottom: 6px;
  }

  ${mediaMax(breakpoints.mobileS)} {
    svg {
      width: 12px;
      height: 12px;
    }
  }

  ${mediaMax(breakpoints.mobileXS)} {
    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const PointText = styled.span<{ large: boolean }>`
  font-size: ${({ large }) => (large ? '18px' : '16px')};
  font-weight: 400;
  margin-left: 9px;

  ${mediaMin(breakpoints.tabletL)} {
    margin-left: 14px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    margin-left: 17px;
  }

  // -1 for screens < 360px
  ${mediaMax(breakpoints.mobileSM - 1)} {
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.h2``;

export const Button = styled.button`
  border-radius: 6px;
  font-weight: 700;
  text-transform: none;
  word-break: break-all;
  word-break: break-word;
  width: 100%;
  height: 60px;
  background-color: var(--qr-global-color-yellow);
  color: var(--qr-global-color-white);
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: var(--qr-global-color-yellow-hover);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  ${mediaMin(breakpoints.mobileL + 1)} {
    max-width: 288px;
  }

  a,
  a:hover {
    text-decoration: none;
    color: var(--qr-global-color-white);
  }
`;

export const StepsButton = styled(Button)`
  margin: 40px auto 0;
`;

export const AppImageWrapper = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;

  ${mediaMin(breakpoints.tabletM)} {
    width: 55%;
  }

  ${mediaMin(breakpoints.tabletL)} {
    width: 531px;
    height: 405px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    width: 785px;
    height: 590px;
  }
`;

export const AppImage = styled.picture`
  margin-top: 44px;
  flex-grow: 1;
  filter: drop-shadow(0px 1px 51px rgba(12, 37, 54, 0.09));

  ${mediaMin(breakpoints.tabletM)} {
    position: absolute;
    margin-top: 0px;
    width: calc(100% - 60px);
    right: 0;
  }

  ${mediaMin(breakpoints.tabletL)} {
    position: absolute;
    margin-top: 0px;
    width: 532px;
    top: -24px;
    left: -15px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    position: absolute;
    width: 785px;
    height: 590px;
    top: -38px;
    left: 35px;
  }

  /** Custom image positiong to prevent horizontal scroll and show full image */
  ${mediaMinMax(1250, 1361)} {
    left: auto;
    right: -22px;
  }

  ${mediaMinMax(breakpoints.tabletL, 1250)} {
    left: auto;
    right: 0;
  }

  ${mediaMax(breakpoints.tabletXS)} {
    display: flex;
  }

  img {
    width: 100%;
  }
`;

export const Img = styled.img`
  height: auto;
`;

export const StepsBarWrapper = styled.div`
  margin-top: 71px;
  width: 100%;
  z-index: 1;

  ${mediaMin(breakpoints.tabletL)} {
    margin-top: 22px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    margin-top: 30px;
  }
`;

export const StepsBlockTitle = styled(BlockTitle)`
  padding: 0 22px;
`;

export const StepsWrapper = styled.ol`
  margin: 0;
  padding: 0;

  ${mediaMin(breakpoints.tabletM)} {
    display: flex;
    flex-flow: row;
    position: relative;
    z-index: 1;
  }
`;
export const StepWrapper = styled.li`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 15px;
  background: var(--qr-global-color-primary-text);
  counter-increment: steps-counter;

  h3 {
    position: relative;
    display: inline-block;
    &::before {
      content: counter(steps-counter) '. ';
      white-space: pre;
    }
  }

  &:first-of-type {
    margin-top: 24px;

    .stepIcon {
      ${mediaMax(breakpoints.tabletM - 1)} {
        transform: translateX(7px);
      }
    }
  }

  &:last-of-type {
    .stepIcon {
      ${mediaMax(breakpoints.tabletM - 1)} {
        transform: translateX(7px);
      }

      ${mediaMin(breakpoints.tabletM)} {
        margin-bottom: 4px;
      }

      ${mediaMin(breakpoints.tabletL)} {
        margin-bottom: 1px;
      }

      ${mediaMin(breakpoints.laptopS)} {
        margin-bottom: 11px;
      }
    }
  }

  ${mediaMin(breakpoints.tabletM)} {
    margin-top: 0px;
    align-items: baseline;
    margin-right: 16px;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  ${mediaMin(breakpoints.tabletL)} {
    min-width: 312px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    margin-top: 0px;
    align-items: baseline;
    width: 374px;
    margin-right: 37px;
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 8px;

  ${mediaMin(breakpoints.tabletL)} {
    margin-bottom: 4px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    margin-bottom: 14px;
  }
`;

export const StepTitle = styled.h3`
  font-weight: 700;
  font-size: var(--qr-global-font-size-default);
  line-height: 24px;
  color: var(--qr-global-color-text-primary);
  text-align: center;
  //odd number by design
  font-size: 15px;

  ${mediaMin(breakpoints.mobileXS)} {
    font-size: 16px;
  }

  ${mediaMin(breakpoints.tabletM)} {
    text-align: left;
  }

  ${mediaMinMax(breakpoints.tabletM, breakpoints.tabletL - 1)} {
    height: 48px;
    max-width: 80%;
  }

  ${mediaMin(breakpoints.tabletL)} {
    font-size: 20px;
    margin-bottom: 0px;
    padding: 0;
    height: 48px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    font-size: 20px;
    margin-bottom: 2px;
    height: auto;
  }
`;

export const StepDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 335px;
  font-size: 15px;
  text-align: center;
  margin-top: 3px;
  color: var(--qr-global-color-text-secondary);

  ${mediaMin(breakpoints.tabletM)} {
    text-align: left;
  }

  ${mediaMin(breakpoints.tabletL)} {
    margin-top: 5px;
    max-width: inherit;
    font-size: 15px;
  }

  ${mediaMin(breakpoints.laptopS)} {
    margin-top: 2px;
    font-size: var(--qr-global-font-size-default);
  }
`;
